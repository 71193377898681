import { useScreen } from '@/hooks/useScreen';
import { useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltips';
import { Typography } from '../Typography';
import { NavItem } from './types';

type NavItemProps = {
  navItem: NavItem;
  expand?: boolean;
};

const NavigationItem = (props: NavItemProps) => {
  const { navItem, expand } = props;
  const { icon, name, onClick, id, url, selectedChild } = navItem;
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { isDesktop } = useScreen();

  useEffect(() => {
    const path = window.location.pathname;

    if (selectedChild) {
      setIsSelected(path.includes(url));
    } else {
      setIsSelected(path === url);
    }
  }, []);

  return (
    <>
      {!isDesktop ? (
        <div
          key={id}
          className={[
            'flex items-center gap-2 py-2',
            isSelected
              ? 'rounded-[100px] border border-Primary/Other(Border) px-4 text-Primary/Main'
              : 'px-2 text-Text/LightBackground/Secondary',
          ].join(' ')}
          onClick={onClick}
        >
          <Icon iconName={icon} />
          {isSelected && (
            <Typography variant="button2" className="whitespace-nowrap">
              {name}
            </Typography>
          )}
        </div>
      ) : (
        <Tooltip title={expand ? undefined : name}>
          <div
            className={[
              'flex h-10  cursor-pointer items-center gap-3 overflow-hidden rounded-[100px] hover:bg-Primary/Other/Hover hover:text-Text/Lightbackground/Primary focus:border focus:border-Primary/Other(Border) focus:text-Text/Lightbackground/Primary active:text-primary_pressed',
              isSelected
                ? 'bg-[#E8F9FF] text-Primary/Main'
                : 'text-Text/LightBackground/Secondary',
              expand ? 'w-full' : 'w-fit',
            ].join(' ')}
            onClick={onClick}
          >
            <Icon iconName={icon} className="px-2" />
            {expand && (
              <Typography variant="body3" className="whitespace-nowrap">
                {name}
              </Typography>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default NavigationItem;

import { useScreen } from '@/hooks/useScreen';
import { Avatar } from 'design-system/components/Avatar';
import { Button } from '../Button';
import { Tooltip } from '../Tooltips';
import { Typography } from '../Typography';

type NavLogoProps = {
  logo: string;
  expand?: boolean;
  onClick: () => void;
  isLogoSelected?: boolean;
};

const NavLogo = (props: NavLogoProps) => {
  const { expand, logo, onClick, isLogoSelected } = props;
  const { isDesktop } = useScreen();

  return (
    <>
      {isDesktop ? (
        <Tooltip title={expand ? undefined : 'Trang cá nhân'}>
          <div
            className="flex cursor-pointer items-center gap-3 px-1"
            onClick={onClick}
          >
            <Avatar type="image" src={logo} />
            {expand && (
              <Typography
                variant="body3"
                className="whitespace-nowrap text-Text/LightBackground/Secondary"
              >
                Trang cá nhân
              </Typography>
            )}
          </div>
        </Tooltip>
      ) : (
        <Button
          className={[
            'z-10 flex h-10 w-10 items-center justify-center p-1',
            !isLogoSelected && 'border-transparent',
          ].join(' ')}
          onClick={onClick}
        >
          <Avatar type="image" src={logo} size={32} />
        </Button>
      )}
    </>
  );
};

export default NavLogo;

import { AvatarGroup } from '@mui/material';
import Avatar from './Avatar';
import { GroupAvatarProps } from './types';

const GroupAvatar = (props: GroupAvatarProps) => {
  const { avatars, ...restProps } = props;

  return (
    <AvatarGroup
      {...restProps}
      sx={{
        '& .MuiAvatar-root': {
          border: 'none',
        },
        '& .MuiBadge-root:last-child .MuiAvatar-root': {
          marginLeft: '0px',
        },
      }}
    >
      {avatars.map((avatar, index) => (
        <Avatar
          key={index}
          type={avatar.type}
          alt={avatar.alt}
          src={avatar.src}
          size={avatar.size || 40}
        />
      ))}
    </AvatarGroup>
  );
};

export default GroupAvatar;

import { AvatarSizes, AvatarTypes } from './types';

export const mappingAvatarStyle = {
  20: {
    textClassName: 'text-[12px] font-medium',
  },
  24: {
    textClassName: 'text-[12px] font-medium',
  },
  32: {
    textClassName: 'text-[16px]',
  },
  40: {
    textClassName: 'text-[16px]',
  },
  64: { textClassName: 'text-[16px]' },
  80: { textClassName: 'text-[16px]' },
  96: { textClassName: '' },
  128: { textClassName: '' },
  200: { textClassName: '' },
};

export const getAvatarClasses = (
  shadow: boolean,
  border: boolean,
  type: AvatarTypes,
  size: AvatarSizes
): string => {
  return [
    // mappingAvatarStyle[size].sizeClassName,
    'bg-Primary/Main',
    shadow && 'shadow-avatar',
    (type === 'name' || size > 24) &&
      border &&
      'border-2 border-Light/Background/Paper',
  ].join(' ');
};

export const getFirstLetterFromName = (name: string) => {
  // eslint-disable-next-line no-useless-escape
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const text = name.match(/\b(\w)/g);
  if (!name) return '';

  if (specialChars.test(name)) return name;

  return text;
};

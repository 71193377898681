/* eslint-disable @next/next/no-img-element */
import { Avatar as AvatarMUI, Badge as BadgeMUI } from '@mui/material';
import dynamic from 'next/dynamic';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Badge } from '../Badge';
import { Icon } from '../Icon';
import {
  getAvatarClasses,
  getFirstLetterFromName,
  mappingAvatarStyle,
} from './helper';
import { AvatarProps } from './types';

const SvgDevice = dynamic(() => import('./SvgDevice'));
const InfoAvatar = dynamic(() => import('./InfoAvatar'));

const Avatar = (props: AvatarProps, _ref: React.Ref<HTMLSpanElement>) => {
  const {
    type = 'name',
    name = '',
    size = 40,
    src,
    shadow = false,
    className,
    icon,
    border = false,
    href = '',
    trustable,
    srcSmallAvatar,
    multiple = false,
    ...restProps
  } = props;

  const isImg = type === 'image';
  const isSymbol = type === 'symbol';
  const isName = type === 'name';
  const isAuthor = type === 'author';
  const isLandscape = type === 'landscape';
  const isThumbnail = type === 'thumbnail';
  const isUpload = type === 'upload';
  const isDevice = type === 'device';
  const isOnlineStatus = type === 'online-status';

  const textClassName = mappingAvatarStyle[size].textClassName;

  const finalSize = isAuthor ? 40 : isLandscape ? 24 : size;

  const finalClassName = twMerge(
    getAvatarClasses(shadow, border, type, finalSize),
    className
  );

  const renderBadge = () => {
    if (!isThumbnail && !isUpload && !isOnlineStatus) return null;

    return (
      <>
        {isUpload ? (
          <Icon
            iconName="add"
            className="cursor-pointer rounded-full bg-Primary/Main text-white"
            width={16}
            height={16}
          />
        ) : isOnlineStatus ? (
          <Badge
            size={10}
            purpose="active-status"
            active-status="online"
            stroke
          />
        ) : trustable ? (
          <Icon
            iconName="badge-tick"
            className="text-Primary/Main"
            width={20}
            height={20}
          />
        ) : (
          <AvatarMUI
            sx={{ width: 20, height: 20 }}
            alt="avatarSmallImg"
            src={srcSmallAvatar}
            className="absolute"
          />
        )}
      </>
    );
  };

  return (
    <BadgeMUI
      ref={_ref}
      className={[
        'relative flex w-fit items-center justify-center',
        isLandscape ? 'flex-row gap-2' : 'flex-col',
      ].join(' ')}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={renderBadge()}
    >
      <AvatarMUI
        {...restProps}
        sx={{ width: finalSize, height: finalSize }}
        className={finalClassName}
      >
        {(isImg ||
          isAuthor ||
          isLandscape ||
          isThumbnail ||
          isUpload ||
          isOnlineStatus) && (
          <img
            className="h-full w-full object-cover"
            alt={restProps.alt}
            src={src}
          />
        )}
        {isSymbol && icon && <Icon iconName={icon} />}
        {isName && (
          <span className={textClassName}>{getFirstLetterFromName(name)}</span>
        )}
        {isDevice && <SvgDevice />}
      </AvatarMUI>

      {(isAuthor || isLandscape) && (
        <InfoAvatar type={type} name={name} href={href} />
      )}
    </BadgeMUI>
  );
};

export default forwardRef(Avatar);
